var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.beforeClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dialogForm",
              attrs: {
                model: _vm.formInline,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Merchant_Name"),
                    prop: "operationId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        size: "15",
                        disabled: _vm.dialogStatus !== "create",
                      },
                      on: { change: _vm.queryParkList },
                      model: {
                        value: _vm.formInline.operationId,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "operationId",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.operationId",
                      },
                    },
                    _vm._l(_vm.tenantList, function (value) {
                      return _c("el-option", {
                        key: value.operationId,
                        attrs: {
                          label: value.operationName,
                          value: value.operationId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.dialogStatus != "check"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "适用范围", prop: "scope" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: _vm.dialogStatus !== "create",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.formInline.scope,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "scope", $$v)
                            },
                            expression: "formInline.scope",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部道路", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "部分道路", value: 2 },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "inputTip" }, [
                        _vm._v("*仅限商户下道路"),
                      ]),
                      _vm.formInline.scope == 2
                        ? _c("div", {
                            staticStyle: { "margin-bottom": "20px" },
                          })
                        : _vm._e(),
                      _vm.dialogStatus !== "check"
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "parkIds" } },
                            [
                              _vm.formInline.scope == 2
                                ? _c("el-transfer", {
                                    attrs: {
                                      "target-order": "push",
                                      titles: ["未选择", "已选择"],
                                      filterable: "",
                                      props: {
                                        key: "parkId",
                                        label: "parkName",
                                      },
                                      "filter-placeholder": "请输入道路名称",
                                      data: _vm.parkList,
                                    },
                                    model: {
                                      value: _vm.selected,
                                      callback: function ($$v) {
                                        _vm.selected = $$v
                                      },
                                      expression: "selected",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dialogStatus == "check"
                ? _c("el-form-item", { attrs: { label: "适用范围" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.formInline.scope == 2
                          ? _vm.formInline.parkName
                          : "全部道路"
                      )
                    ),
                  ])
                : _vm._e(),
              _vm.dialogStatus !== "create"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("searchModule.License_plate_color"),
                        prop: "plateColor",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { disabled: _vm.dialogStatus !== "create" },
                          model: {
                            value: _vm.formInline.plateColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "plateColor", $$v)
                            },
                            expression: "formInline.plateColor",
                          },
                        },
                        _vm._l(_vm.plateColorList, function (item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.desc, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dialogStatus !== "create"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("searchModule.plate_number"),
                        prop: "plateNumber",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: {
                          maxlength: "10",
                          disabled: _vm.dialogStatus !== "create",
                        },
                        model: {
                          value: _vm.formInline.plateNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline,
                              "plateNumber",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formInline.plateNumber",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._l(
                    _vm.formInline.numberRuleActivities,
                    function (val, ind) {
                      return _c(
                        "el-form-item",
                        {
                          key: ind,
                          attrs: { label: "车牌号码", required: true },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                width: "100px",
                                display: "inline-block",
                              },
                              attrs: {
                                prop:
                                  "numberRuleActivities." + ind + ".numberName",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择车牌号码",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: val.numberName,
                                    callback: function ($$v) {
                                      _vm.$set(val, "numberName", $$v)
                                    },
                                    expression: "val.numberName",
                                  },
                                },
                                _vm._l(_vm.platerNumberLists, function (item) {
                                  return _c("el-option", {
                                    key: item.numberId,
                                    attrs: {
                                      disabled: item.disabled && item.disabled,
                                      label: item.numberName,
                                      value: item.numberName,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                width: "210px",
                                padding: "0 20px 0 5px",
                                display: "inline-block",
                              },
                              attrs: {
                                prop:
                                  "numberRuleActivities." +
                                  ind +
                                  ".plateNumber",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入车牌号码",
                                    trigger: "change",
                                  },
                                  {
                                    required: true,
                                    validator: _vm.checkNumber,
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled:
                                    !_vm.parkCardIsActive && !_vm.isNowDate,
                                  maxlength: 7,
                                  placeholder: "请输入车牌号",
                                },
                                model: {
                                  value: val.plateNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      val,
                                      "plateNumber",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "val.plateNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" 车牌颜色 "),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: {
                                width: "100px",
                                display: "inline-block",
                              },
                              attrs: {
                                prop:
                                  "numberRuleActivities." + ind + ".plateColor",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择车牌颜色",
                                    trigger: "change",
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  ref: "optionRef",
                                  refInFor: true,
                                  attrs: {
                                    disabled:
                                      !_vm.parkCardIsActive && !_vm.isNowDate,
                                    placeholder: "请选择",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changePlate(
                                        val,
                                        val.plateColor,
                                        ind
                                      )
                                    },
                                  },
                                  model: {
                                    value: val.plateColor,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        val,
                                        "plateColor",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "val.plateColor",
                                  },
                                },
                                _vm._l(_vm.colorList, function (v) {
                                  return _c("el-option", {
                                    key: v.code,
                                    attrs: { label: v.desc, value: v.code },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          ind == 0
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "button-style",
                                  attrs: { type: "primary", plain: "" },
                                  on: { click: _vm.addNumber },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-plus icon-style",
                                  }),
                                ]
                              )
                            : _c(
                                "el-button",
                                {
                                  staticClass: "button-style",
                                  attrs: { type: "warning", plain: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteNumber(ind)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-minus icon-style",
                                  }),
                                ]
                              ),
                        ],
                        1
                      )
                    }
                  ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Car_owner_name"),
                    prop: "carOwner",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: {
                      maxlength: "10",
                      disabled: _vm.dialogStatus === "check",
                    },
                    model: {
                      value: _vm.formInline.carOwner,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "carOwner", $$v)
                      },
                      expression: "formInline.carOwner",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.phone_number"),
                    prop: "mobile",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: {
                      maxlength: "11",
                      disabled: _vm.dialogStatus === "check",
                    },
                    model: {
                      value: _vm.formInline.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "mobile", $$v)
                      },
                      expression: "formInline.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.classification"),
                    prop: "specialPlateType",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: _vm.dialogStatus === "check" },
                      model: {
                        value: _vm.formInline.specialPlateType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "specialPlateType", $$v)
                        },
                        expression: "formInline.specialPlateType",
                      },
                    },
                    _vm._l(_vm.specialPlateTypeList, function (item) {
                      return _c("el-option", {
                        key: item.code,
                        attrs: { label: item.desc, value: item.code },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "车牌状态", prop: "carStatus" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: _vm.dialogStatus === "check" },
                      model: {
                        value: _vm.formInline.carStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "carStatus", $$v)
                        },
                        expression: "formInline.carStatus",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "启用", value: 1 } }),
                      _c("el-option", { attrs: { label: "停用", value: 0 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "图片", prop: "fileList" } },
                [
                  _c(
                    "el-upload",
                    {
                      class: { hide: _vm.hideUploadEdit },
                      attrs: {
                        action: "",
                        "list-type": "picture-card",
                        "on-remove": _vm.handleRemove,
                        "http-request": _vm.handleFiles,
                        "file-list": _vm.formInline.fileList,
                        disabled: _vm.dialogStatus == "check",
                      },
                    },
                    [_c("i", { staticClass: "el-icon-plus" })]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.dialogFormClose } }, [
                _vm._v("取 消"),
              ]),
              _vm.dialogStatus !== "check"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.commitConfirm },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }